import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import langsObj from '../utils/Langs'
import '../assets/stylesheets/index.scss';

export const siteQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
        keywords
        description
        tdsPath
        tmPath
      }
    }
  }
`;

const Layout = ({ children, lang}) => {
  const langCode = langsObj.getLangsCodes()
  const isoCode = langCode[lang] ? langCode[lang] : 'en';

    return (
    <StaticQuery
      query={siteQuery}
      render={({ site: { siteMetadata } }) => (
        <>
          {/* prettier-ignore */}
          <Helmet defaultTitle={siteMetadata.title}>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta charset="utf-8" />
            <meta name="description" content={siteMetadata.description} />
            <meta name="keywords" content={siteMetadata.keywords} />
            <meta property="og:title" content={siteMetadata.title} />

            <script src={`${siteMetadata.tdsPath}/scripts/th-element-loader.js`}></script>
            <script src={`${siteMetadata.tmPath}/modules/tm.js`} type="module"></script>
            <script src={`${siteMetadata.tdsPath}/modules/tds.js`} type="module"></script>
            <link href={`${siteMetadata.tdsPath}/css/tds-theme-provider.css`} rel="stylesheet" />
            <link href={`${siteMetadata.tdsPath}/css/tds.css`} rel="stylesheet" />
            <link rel="canonical" href={`https://trailhead.salesforce.com/en/trailhead-go`} />
            {isoCode && <meta http-equiv="content-language" content={isoCode} /> }
            <link href={`https://trailhead.salesforce.com/trailhead-go`} hreflang="x-default" rel="alternate"></link>
            {
                Object.keys(langCode).map(key => {
                    return (<link href={`https://trailhead.salesforce.com/${key}/trailhead-go`} hreflang={langCode[key]} rel="alternate"></link>)
                })
            }
          </Helmet>
          <div id="dsc-content" className="dsc-content">
            {children}
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
